<template>
  <div class="home">
    <div class="header_video">
      <video
          src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/IMG_1412.mp4"
          autoplay="autoplay" loop="loop" width="100%" muted="muted"></video>
      <div
          style="width: 100%;position: absolute;top:0;left: 0;right: 0;bottom: 0.6vh;background: #000000;opacity: .5;"></div>
      <div class="header_title">
        <div>全方位资产运营管理·办公全维度增值服务</div>
        <div style="cursor: pointer" @click="$message.warning('正在开发中...')"><img
            src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/download.png"></div>
      </div>
      <icon-svg icon-class="icon-mouse" class="video_icon"/>
    </div>
    <div class="content">
      <div class="title">
        <div>境云数据提供</div>
        <div>全面的运营管理服务，实现资产价值最大化</div>
      </div>
      <div class="card">
        <div>
          <!--          <icon-svg icon-class="icon-video"/>-->
          <img src="https://oss.ziyouzhaofang.com/JSMp4/home_icon_kehu.svg"/>
          <div>客户关系管理</div>
          <div>通过使用移动招商，实现更新客户需求、抗性、意向度和沟通进程，结合待租房源与当前租客到期情况，提前做出最合理的租赁方案。</div>
        </div>
        <div>
          <!--          <icon-svg icon-class="icon-people"/>-->
          <img src="https://oss.ziyouzhaofang.com/JSMp4/home_icon_yizhanshi.svg"/>
          <div>一站式服务</div>
          <div>依靠大数据、人工智能等信息技术实现精准推送公告推送、低成本的创业创新服务，极大提高企业获得感。</div>
        </div>
        <div>
          <!--          <icon-svg icon-class="icon-social"/>-->
          <img src="https://oss.ziyouzhaofang.com/JSMp4/home_icon_business.svg"/>
          <div>商务服务</div>
          <div>通过数字化工具，实现快速生成合同，自动化账单出具，自定义合同编辑与催款通知，实现从合同签订到日常租赁管理的完整数字化流程。</div>
        </div>
        <div>
          <!--          <icon-svg icon-class="icon-center"/>-->
          <img src="https://oss.ziyouzhaofang.com/JSMp4/home_icon_zengzhi.svg"/>
          <div>增值服务</div>
          <div>覆盖企业服务全应用场景，包括但不限于智能停车，租赁账单，水电缴费，投诉建议，访客预约，园区活动，公告信息等核心应用功能，将企业服务信手拈来。</div>
        </div>
      </div>
      <div class="title">
        <div>境云数据业务范围</div>
        <div>规划定位，改造升级，物业管理，招商运营，资产运营，企业服务，ICC轻资产运营模式</div>
      </div>
      <div class="allPeople">
        <div>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item name="1">
              <template slot="title">
                <div class="collapse_header">
                  <!--                  <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/allPeople1.png"
                                         class="icon_img" alt="精选房源">-->
                  <span class="collapse_title">招商管理</span>
                </div>
              </template>
              <div class="collapse_content">
                实时掌握项目楼宇管理，根据不同项目、楼宇查看报备、客户、租控可招商、商务条款数量，实现可视化管控。
              </div>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <div class="collapse_header">
                  <!--                  <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/allPeople2.png"
                                         class="icon_img" alt="服务大厅">-->
                  <span class="collapse_title">房源租控可视化</span>
                </div>
              </template>
              <div class="collapse_content">
                精细化房源管控，以剖面图形式、多颜色区分展示房源状态如（预租、已租、空置）。
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <div class="collapse_header">
                  <!--                  <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/allPeople3.png"
                                         class="icon_img" alt="即时消息">-->
                  <span class="collapse_title">楼书一键推广</span>
                </div>
              </template>
              <div class="collapse_content">
                可将项目、房源信息发布至小程序，实现资源在线推荐。 多渠道分享途径，可通过微信好友、小程序等多种当时分享。
              </div>
            </el-collapse-item>
            <el-collapse-item name="4">
              <template slot="title">
                <div class="collapse_header">
<!--                  <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/allPeople4.png"
                       class="icon_img" alt="个人主页">-->
                  <span class="collapse_title">一站式服务</span>
                </div>
              </template>
              <div class="collapse_content">
                自助在线查看待缴费账单明细，提升租客体验。
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div>
          <img src="https://oss.ziyouzhaofang.com/JSMp4/allPeople1-1.png"
               v-show="activeName === '1' || activeName === ''" alt="服务大厅"/>
          <img src="https://oss.ziyouzhaofang.com/JSMp4/allPeople2-1.png"
               v-show="activeName === '2'" alt="服务大厅"/>
          <img src="https://oss.ziyouzhaofang.com/JSMp4/allPeople3-1.png"
               v-show="activeName === '3'" alt="即时消息"/>
          <img src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/allPeople4-1.png"
               v-show="activeName === '4'" alt="个人主页"/>
        </div>
      </div>
      <div class="title">
        <div>用视频认识我</div>
        <div>短视频平台高效分发，房态一键更新</div>
      </div>
      <div class="content_video">
        <video
            src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/488%C3%97600-1.mp4"
            autoplay="autoplay" loop="loop" muted="muted"></video>
        <video
            src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/386%C3%97600-2.mp4"
            autoplay="autoplay" loop="loop" muted="muted"></video>
        <div>
          <video
              src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/284%C3%97288-3.mp4"
              autoplay="autoplay" loop="loop" muted="muted"></video>
          <video
              src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/284%C3%97288-4.mp4"
              autoplay="autoplay" loop="loop" muted="muted"></video>
        </div>
      </div>
      <!--      <div class="title">
              <div>5W+ 经纪人 去中心化高效节点</div>
              <div>搭建经纪人信息传播枢纽，完成对客户的深度运营</div>
            </div>-->
      <!--      <div style="padding: 10px 0px 100px;">
              <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/people.png" alt="经纪人"
                   width="100%">
            </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      activeName: '1'
    }
  }
}
</script>
<style lang="scss" scoped>

.header_video {
  position: relative;

  video {
    height: 100%;
  }

  .header_title {
    position: absolute;
    top: 20vw;
    font-size: 60px;
    color: #FFFFFF;
    //letter-spacing: 34px;
    //padding-left: 30vw;
    width: 100%;
    text-align: center;
    letter-spacing: 20px;

    div {
      text-align: center;
      padding-bottom: 40px;
    }
  }

  .video_icon {
    position: absolute;
    bottom: 5vh;
    left: 50%;
    font-size: 40px;
    animation-name: beat;
    /*动画名称*/
    animation-duration: .5s;
    /*设置秒数*/
    animation-timing-function: linear;
    /*速度曲线*/
    animation-iteration-count: infinite;
    /*播放次数*/
    animation-direction: alternate;
    /*逆向播放*/
    animation-play-state: running;
    /*正在运行*/
  }


  @keyframes beat {
    0% {
      bottom: 5vh;
    }
    20% {
      bottom: 5.1vh;
    }
    40% {
      bottom: 5.2vh;
    }
    60% {
      bottom: 5.3vh;
    }
    80% {
      bottom: 5.4vh;
    }
    100% {
      bottom: 5.5vh;
    }
  }
}

.content {
  padding: 0 15vw;
  margin: 0 auto;

  .title {
    text-align: center;
    padding: 15vh 0 8vh 0;
    //animation-name: nam;
    /*动画名称*/
    animation-duration: .5s;
    /*设置秒数*/
    animation-timing-function: linear;
    /*速度曲线*/
    animation-iteration-count: infinite;
    /*播放次数*/
    animation-direction: alternate;
    /*逆向播放*/
    animation-play-state: running;
    /*正在运行*/
    position: relative;

    div {
      padding: 1.5vh;

      &:first-child {
        font-size: 50px;
        font-weight: bold;
        color: #020F1B;
        line-height: 48px;
      }

      &:last-child {
        font-size: 26px;
        color: #999999;
        line-height: 32px;
      }
    }
  }

  @keyframes nam {
    0% {
      top: 1px;
    }
    20% {
      top: 16px;
    }
    40% {
      top: 12px;
    }
    60% {
      top: 8px;
    }
    80% {
      top: 4px;
    }
    100% {
      top: 0px;
    }
  }

  .card {
    display: flex;
    justify-content: space-between;
    //padding-bottom: 240px;

    .svg-icon {
      font-size: 40px;
    }

    img {
      width: 60px;
      height: 60px;
    }

    & > div {
      border-radius: 10px;
      border: 1px solid #F2F0F0;
      padding: 3.65vw 2.1vw 3.65vw 2.1vw;
      margin-right: 2.1vw;
      width: 14vw;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-bottom: 2px solid #b6923e;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        /*margin-bottom: -1px;
        margin-top: -10px;*/
        position: relative;
        top: -15px;
        margin-bottom: -1px;
      }

      :nth-child(2) {
        font-size: 26px;
        color: #020F1B;
        line-height: 32px;
        padding: 40px 0 20px 0;
        font-weight: bold;
      }

      :nth-child(3) {
        font-size: 14px;
        color: #999999;
        line-height: 26px;
      }
    }
  }

  .allPeople {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 60px 0 0 0;

    .el-collapse {
      border-color: #F5F3F3;

      .el-collapse-item {
        ::v-deep .el-icon-arrow-right:before {
          font-weight: bold;
        }

        ::v-deep .el-collapse-item__header {
          height: 70px;
          line-height: 70px;
          border-color: #F5F3F3;

          &.is-active {
            border-bottom-color: transparent;
          }
        }

        ::v-deep .el-collapse-item__wrap {
          border-color: #F5F3F3;
        }
      }
    }

    img {
      width: 100%;
    }

    & > div {
      &:first-child {
        flex: .6;

        .collapse_header {
          display: flex;
          align-items: center;

          .icon_img {
            width: 35px;
            height: 35px;
            padding-right: 20px;
          }

          .collapse_title {
            font-size: 20px;
            color: #020F1B;
            line-height: 32px;
            font-weight: bold;
          }
        }

        .collapse_content {
          font-size: 16px;
          color: #999999;
          line-height: 32px;
          padding: 20px 46px;
        }

      }

      &:last-child {
        flex: .3
      }
    }
  }

  .content_video {
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
    //padding: 10vh 0;

    & > video {
      //width: 35vw;
      height: 35vw;
      border-radius: 20px;
      margin-right: 1vw;
      background: #000000;
    }

    & > div {
      video {
        display: block;
        width: 16.8vw;
        margin-bottom: 1.8vh;
        border-radius: 20px
      }
    }
  }
}
</style>
s
